import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './plugins/sembly.js';
import "./plugins/element.js";
import MD5 from 'js-md5';


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.prototype.$md5 = MD5;
router.beforeEach((to, from, next) => {
	next();
	store.commit('setLabel', to.meta.title);
})
new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");

// 动态设置seo