import Vue from "vue";
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// Vue.use(Element, { // 未配置部分会丢失，如时间日期选择器等
//   // locale: {
//   //   el: {
//   //     pagination: {
//   //       goto: '跳至',
//   //       pagesize: '条/页',
//   //       // total: `共计 {total} 件商品`,
//   //       pageClassifier: '页',
//   //     },
//   //   },
//   // },
// });
Vue.use(Element);
