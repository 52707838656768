import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
const routes = [{
	path: '/',
	name: "home",
	component: () => import( /* webpackChunkName: "about" */ "@/views/home/index.vue")
}];
const router = new VueRouter({
	mode: "history",
	routes,
});
router.beforeEach((to, from, next) => {
	if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
		if (localStorage.getItem("TOKEN")) { // 判断本地是否存在token
			next()
		} else {
			next({
				path: '/login'
			})
		}
	} else {
		next();
	}
});
export default router;