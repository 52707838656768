<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: "app",
		data() {
			return {

			};
		},
		created() {

		},
		methods: {},
	};
</script>

<style lang="less">
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		/* line-height: 150%; */
		scroll-padding-top: 80px;
		scroll-behavior: smooth;
	}

	#app {
		font-family: "Avenir", Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-size: 18px;
		min-width: 1200px !important;
	}

	.width1200 {
		width: 1200px;
	}

	.icon_image {
		display: flex;
		align-content: center;
		justify-items: center;
		overflow: hidden;

		>img {
			width: 100%;
			height: 100%;
			object-fit: fill;
		}
	}
</style>