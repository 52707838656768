import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		navHader: 0,
		// 上传作品进度
		schedule: 0,
		user_id: 0,
		headTxtIndex: '',
		spin: {
			spintip: '请稍等',
			spinShow: false,
		},
		record: [],
		search: '',
		classifyShow: true,
		setLabel: '',
		count: 0,
		metaInfo: {
			keywords: '丰信、牙科、牙科耗材、牙科器械、牙科设备、医疗设备、医疗器械、牙科咨询、口腔咨询、牙科售后服务、医学学术交流、牙科在线培训、医学设备维修、行业金融。',
			description: '内蒙古丰信医药有限公司牙科专业电商平台，是集牙科耗材、器械交易，金融服务，专业咨询，学术交流，学习培训，行业直播，设备维修，售后服务为一体的综合性网络服务平台。丰信医药以“让口腔产品交易变得简单高效”为使命，为口腔诊所提供一站式综合解决方案。'
		}
	},
	mutations: {
		setScheDule(state, val) {
			console.log(val);
			state.schedule = val;
		},
		setHeadIndex(state, val) {
			state.headTxtIndex = val;
		},
		amendUserId(state, val) {
			state.user_id = val
		},
		setLabel() {

		},
		upmetaInfoKeywords(state, val) {
			state.metaInfo.keywords = val;
		},
		upmetaInfoDescription(state, val) {
			state.metaInfo.description = val;
		},
		modificationSpin(state, val) {
			state.spin = val;
		},
		updateRecord(state, val) {
			val.forEach((item) => {
				item.type = item.children ? 'class' : item.name ? 'text' : 'delete'
			})
			state.record = val;
		},
		updateCount(state, val) {
			state.count = val;
		},
		updateSearch(state, val) {
			console.log(val);
			state.search = val;
		},
		updateClassifyShow(state, val) {
			state.classifyShow = val;
		}
	},
	actions: {},
	modules: {},
});
